:root {
  --primary-bg: #242526;
  --secondary-bg: #fcfafae5;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --border-radius: 8px;
  --speed: 500ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Quattrocento Sans', sans-serif;
  /* font-size: 16px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #myButton{
  position: fixed;
  top: 190px;
  left: 5px;
  height: 33px;
  width: 32px;
  border-radius: 8%;
  cursor: pointer;
  z-index: 10500;
  margin-bottom: 5px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==');
  color: black;
  border: none;
  float: right;
  box-shadow: inset 0 0 6px rgba(238, 236, 236, 0.76);
  background-color: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

} */

/**/
.menu-trigger {
  position: fixed;
  top: 150px;
  right: 5px;
  height: 33px;
  width: 32px;
  border-radius: 8%;
  /* overflow: hidden; */
  cursor: pointer;
  z-index: 1000;
  margin-bottom: 5px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==');
  color: black;
  border: none;
  float: right;
  box-shadow: inset 0 0 6px rgba(238, 236, 236, 0.76);
}

@media screen and (max-width: 768px) {
  .menu-trigger:hover::before {
    top: 40px;
    left: 0;
    transform: translateX(0);
  }
}

.menu-trigger .menu-icon {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  position: absolute;
  top: 192px;
  right: 2px;
  background-color: #fcfafaea;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 248px;
  z-index: 900;
  font-size: 10px;
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -4px;
  right: 4px;
  height: 20px;
  width: 20px;
  background: #fcfafa59;
  transform: rotate(49deg);
}

.dropdown-menu.active {
  opacity: 2;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
  right: 15px;
  width: 235px;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.dropdown-menu ul li {
  padding: 9px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a {
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.dropdown-menu ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.dropdown-menu h3 {
  padding-bottom: 3.8px;
  padding-left: 9px;
}

/*submenu */
.submenu-trigger {
  display: inline-block;
  vertical-align: middle;
  margin-left: 11px;
  cursor: pointer;
  color: black;
}

.submenu-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;
}

.submenu-icon.open {
  color: red;
}

.submenu-icon .icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.submenu-icon.open .icon {
  transform: rotate(90deg);
}

.submenu-trigger .submenu-icon::before {
  content: '\2B9F';
  margin-right: 5px;
  vertical-align: top;
  transition: transform 0.3s ease;
  font-size: 11px;
}

.submenu-trigger.open .submenu-icon::before {
  content: '\2B9D';
  margin-right: 5px;
  vertical-align: middle;
  transition: transform 0.3s ease;
  font-size: 11px;
}

.submenu-icon.open::after {
  transform: rotate(90deg);
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.submenu.open {
  max-height: 500px;
  /* Defina a altura máxima apropriada */
}

/*submenu */

.dropdownItem {
  display: flex;
  margin: 10px auto;
}

.dropdownItem img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}

/* */

.map:-webkit-full-screen {
  height: 94%;
  margin: 0;
}

.map:-ms-fullscreen {
  height: 94%;
}

.map:fullscreen {
  height: 94%;
}

/* position the rotate control lower than usual */
.map .ol-rotate {
  top: 3em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 2em;
}

.custom-mouse-position {
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 11px;
  font-weight: bold;
  left: 45%;
  bottom: 3px;
  background-color: white;
  border-radius: 2px;
  border-width: 2px;
  position: absolute;
  align-content: center;
}

.layer-switcher {
  position: absolute;
  top: 5.5em;
  right: 0.5em;
  text-align: left;
  transition: width 0.3s;
  overflow: hidden;
}

.layer-switcher .panel {
  font-size: 12px;
  margin: 0;
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  /* display: none; */
  max-height: inherit;
  height: 100%;
  width: 210px;
  box-sizing: border-box;
  overflow-y: auto;
}

.ol-control button {
  width: 25px;
  height: 25px;
}

/* Add this CSS to your stylesheet or style block */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  animation: spin 1s linear infinite; /* animação de girando */
}

#customPopup {
  position: absolute;
  top: 23%;
  left: 12%;
  transform: translate(-50%, -50%);
  padding: 12px 30px 12px 12px;
  background-color: #ffffffda;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 9999;
  display: none;
}

#customPopup.show {
  display: block;
}

#customPopup p {
  margin: 0;
  font-size: 14px;
}

#customPopup .closeButton {
  position: absolute;
  top: 7px;
  right: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  color: #db2828;
}

#botao {
  position: absolute;
  height: 25.4px;
  width: 25.4px;
  /* z-index: 5000; */
  border: 1px solid rgba(15, 15, 15, 0.274);
  /* top: 97.9px; */
  left: 8px;
  top: 169px;
  float: left;
  display: block;
  clear: both;
  /* cursor: pointer; */
  max-width: 17em;
  overflow-x: hidden;
  z-index: 1;
  background: #fff;
  border-radius: 8%;
}

#botao:hover {
  border: 1px solid rgba(41, 38, 38, 0.952);
  border-radius: 9%;
}

#botao > button:before {
  content: attr(title);
  pointer-events: none;
  content: '';
  position: absolute;
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  background-color: transparent;
  border: 0.35em solid currentColor;
  border-radius: 100%;
  top: 50%;
  /* Adjust the positioning to center the circle */
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the circle using transform */
  color: rgba(76, 255, 0, 0.8);
}

#botao:hover:before {
  display: block;
  /* Show the tooltip on hover */
}

.ol-control.ol-search > button:before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  background-color: transparent;
  border: 0.12em solid currentColor;
  border-radius: 100%;
  top: 0.68em;
  left: 0.6em;
}

.ol-control.ol-search > button:after {
  content: '';
  position: absolute;
  top: 1.4em;
  left: 1.2em;
  width: 0.45em;
  height: 0.15em;
  background-color: currentColor;
  border-radius: 0.05em;
  transform: rotate(45deg);
  box-shadow: -0.18em 0 0 -0.03em;
}

.ol-search button.ol-revers:before {
  content: '';
  border: 0.1em solid currentColor;
  position: absolute;
  left: 48%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 0.7em;
  /* height: 0.55em; */
  box-sizing: border-box;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==')
    /*logo.png*/;
  background-repeat: no-repeat;
  background-position: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: black;
  border: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  /* display: block; */
  display: none;
  background-image: unset;
  right: 0px;
  border-left: 0;
  float: right;
  background-color: rgb(163, 182, 248);
  margin: 0 0px;
  line-height: 0.1em;
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  position: relative;
  margin-top: 0.3em;
}

.layer-switcher li input {
  position: absolute;
  left: 1.2em;
  height: 1em;
  width: 1em;
  font-size: 1em;
}

.layer-switcher li label {
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
  margin-top: 1px;
}

.layer-switcher label.disabled {
  opacity: 0.4;
}

.layer-switcher input {
  margin: 0px;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center 2px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==');
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.border-black {
  border-color: #000 !important;
  border: 1px solid;
}

.ol-search ul {
  color: #333;
  font-size: 0.85em;
  max-width: 21em;
}

.ol-search ul i {
  display: block;
  color: #333;
  font-size: 0.85em;
}

#slide_help {
  position: absolute;
  top: 0%;
  left: 0%;
  margin-top: 0px;
  margin-left: 0px;
  width: 94%;
  height: 94%;
  z-index: 500;
}

.panelLegenda {
  position: absolute;
  font-size: small;
  margin: 0;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  display: block;
  max-height: inherit;
  /* width: 150px;
  height: 150px;*/
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 5;
  right: 7px;
  bottom: 550px;
  width: 210px;
}

.tabelaLegenda {
  /* display: table;
  border-collapse: separate;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border-spacing: 3px;
  border-color: gray; */
  font-variant: normal;
  padding-right: 8px;
  /* box-sizing: border-box; */
}

.info {
  /* position: absolute;
  top: 0.5em;
  right: 0.5em;*/
  padding: 5px;
  text-align: left;
  /* font-family: 'Verdana'; */
  /* font-size: 12px; */
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, 0.116);
  border-radius: 5px;
  background-color: #eeeeeee8;
  height: 100%;
  opacity: 0;
  transition: var(--speed) ease;
}

.info.show {
  opacity: 1;
}

.custom-zoom-to-extent-control {
  margin-top: 75px;
  /* Ajuste essa propriedade para posicionar o botão para baixo */
  left: 7.2px;
}

.redirect-button {
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  background-color: var(--base);
  transition: 0.3s ease;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.redirect-button:hover {
  letter-spacing: 1.1px;
}
